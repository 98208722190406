.dropdown__container-dropdown--multiselect {
  display: flex;
  cursor: pointer;
}

.dropdown__container-dropdown--multiselect .input_field__dropdown.classic {
  position: inherit;
  top: 0;
  max-height: 140px;
}

.dropdown__container-dropdown--multiselect .input_field__input_box--text {
  position: relative;
  display: grid;
  height: fit-content;
  grid-template: auto / repeat(4
  , auto);
  row-gap: 7.5px;
  min-height: 42px;
}

.dropdown__container-dropdown--multiselect .input_field__input_box--text svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown__container-dropdown--multiselect.tags .input_field__dropdown.classic .dropdown__item {
  position: relative;
  width: 100%;
  display: grid;
  grid-template: auto / 1fr 20px;
  height: 40px;
}

.dropdown__container-dropdown--multiselect .input_field__dropdown.classic .dropdown__item--single {
  position: relative;
  width: 100%;
  display: grid;
  grid-template: auto / 1fr 10px;
}

.dropdown__container-dropdown--multiselect .input_field__dropdown.classic .dropdown__item > div {
  display: flex;
  align-items: center;
}

.dropdown__container-dropdown--multiselect .input_field__dropdown.classic .dropdown__item .dropdown_item_option {
  font-size: 1.1rem;
  color: var(--secondairy-light-medium-gray);
  display: none;
}

.dropdown__container-dropdown--multiselect .input_field__dropdown.classic .dropdown__item:hover .dropdown_item_option {
  display: block;
}

.dropdown__container-dropdown--multiselect .input_field__dropdown.classic .dropdown__item .dropdown_checkmark{
  display: none;
}

.dropdown__container-dropdown--multiselect .input_field__dropdown.classic .dropdown__item--single .dropdown_checkmark{
  display: none;
}

.dropdown__container-dropdown--multiselect .dropdown-input--text_field {
  position: relative;
  background-color: var(--secondairy-light-gray);
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 7.5px;
  font-weight: 400;
  color: var(--primary-black);
  font-size: 0.6rem;
  height: 25px;
  min-width: 50px;
}

.dropdown__container-dropdown--multiselect .dropdown-input--text_field svg {
  color: var(--secondairy-medium-gray)
}

.dropdown__container-dropdown--multiselect .dropdown-input--text_field.gray {
  background-color: var(--secondairy-light-gray);
}

.dropdown__container-dropdown--multiselect .dropdown-input--text_field svg {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8rem;
  background-color: white;
  border-radius: 50%;
  height: 10px;
  transform: translate(40%, -40%);
}

.dropdown__container-dropdown--multiselect .search-bar_dropdown_container {
  background-color: white;
  border-radius: 7.5px;
  padding: 7.5px;
  padding-left: 2.5px;
  font-size: 0.6rem;
  font-weight: 500;
  height: 30px;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
  box-shadow: none;
  border: none;
  border-radius: 0;
  width: 100%;
  cursor: text;
}

.dropdown__container-dropdown--multiselect .search-bar_dropdown_container .search-bar {
  border-bottom: none;
}

.search-bar_dropdown_container button {
  border: none;
  padding: 3px 15px;
  font-size: 0.65rem;
  border-radius: 5px;
  background-color: var(--light-green);
  font-weight: 500;
  cursor: pointer;
  color: var(--dark-green);
}







