* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

html,
body {
  margin: 0;
  padding: 0;
}

/* Custom scrollbar for Chrome, Safari and Opera */

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  background: var(--secondairy-ultra-light-gray);
  border-radius: 10px;
  opacity: 0.5;
  margin-block: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background: var(--secondairy-light-gray);
  border-radius: 10px;
}

body::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: black;
}

:root {
  --primary-black: rgba(30, 30, 30, 1); /*#1E1E1E*/
  --primary-color: rgb(4, 67, 76, 1); /*#04434c*/
  --primary-color-light: rgb(20, 144, 163); /*#1490A3*/
  --primary-color-ultra-light: rgba(81, 178, 199, 0.561);
  --secondairy-dark-gray: rgba(50, 50, 50, 1); /*#323232*/
  --secondairy-medium-gray: rgba(95, 95, 95, 1); /*#5F5F5F*/
  --secondairy-light-medium-gray: rgb(207, 207, 207);
  --secondairy-light-gray: rgba(235, 235, 235, 1);
  --secondairy-ultra-light-gray: rgba(250, 250, 250, 1);

  --border-shadow: rgba(50, 50, 93, 0.2) 0.8px 0.8px 1px 0.5px;
  --border-shadow-min: rgba(50, 50, 93, 0.10) 0.5px 0.5px 1px 0px;
  --border-shadow-float: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --border-shadow-allround: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

  --red: rgb(252, 52, 52);
  --light-red: rgb(250, 210, 210);
  --dark-red: rgb(155, 64, 64);
  --ultra-light-red: rgba(240, 205, 205, 0.5);
  --green: rgb(73, 171, 121);
  --light-green: rgb(182, 224, 202);
  --ultra-light-green: rgb(182, 224, 202, 0.3);
  --dark-green: rgb(71, 95, 70);

  --light-orange: rgb(211, 197, 187);
  --dark-orange: rgb(207, 107, 40);
  --light-brown: rgb(158, 179, 158);
  --dark-brown: rgb(47, 63, 47);

  --blue: rgb(30, 64, 175);
  --light-blue: rgb(149, 194, 253);
  --ultra-light-blue: rgba(174, 203, 241, 0.542);
  --special-gold: rgb(211, 187, 108);
  --special-gold-light: #f7d200;
  --special-gold-dark: rgb(161, 125, 3);
  --special-silver: rgb(192, 192, 192);
  --special-bronze: rgb(205, 127, 50);
}

.template {
  display: grid;
  grid-template: auto auto / min-content auto;
  grid-template-areas:
    "aside header"
    "aside body";
  background: linear-gradient(180deg, var(--secondairy-ultra-light-gray) 0%, var(--secondairy-light-gray) 100%);
  max-width: 100vw;
}

.template__aside {
  grid-area: aside;
}

.template__body {
  position: relative;
  grid-area: body;
  height: 90vh;
  padding: 20px;
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(4, 1fr);
  gap: 10px;
}

.template__header {
  grid-area: header;
}

.body__section {
  position: relative;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 7.5px;
  box-shadow: var(--border-shadow-min);
  padding: 15px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: height 500ms ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;
}

.body__section h2 {
  position: absolute;
  top: 9px;
  left: 20px;
  width: fit-content;
  padding: 0 10px;
  color: var(--secondairy-medium-gray);
  background-color: white;
  font-size: 0.6rem;
}

.body__section.big {
  height: 100% !important;
}

.body__section.hide {
  display: block !important;
  opacity: 0;
  padding: 0;
  margin: 0;
  height: 0% !important;
  pointer-events: none;
}

/* Toasts */

.Toastify__progress-bar {
  background-color: black !important;
}

.Toastify__close-button {
  color: var(--primary-black) !important;
}

.Toastify__toast--error {
  background-color: var(--red) !important;
  color: var(--primary-black) !important;
  font-size: 0.9rem;
}

.Toastify__toast--success {
  background-color: var(--green) !important;
  color: var(--primary-black) !important;
  font-size: 0.9rem;
}

/*  Sections  */

.header__filters {
  position: absolute;
  display: flex;
  justify-content: right;
  width: 80%;
  padding: 0 20px;
  right: 0;
  bottom: 0;
  gap: 7.5px;
}

.header__options--right {
  display: flex;
  align-items: flex-end;
  /* flex-direction: column; */
  column-gap: 7.5px;
  z-index: 60;
  height: 100%;
  padding: 0;
  right: 0;
  margin-left: auto;
  margin-right: 0;
}

.form__section {
  padding: 10px;
  border: var(--secondairy-light-gray) solid 1px;
  border-radius: 10px;
  overflow-y: scroll;
  height: 100%;
}

.form__section::-webkit-scrollbar {
  display: none;
}

.form__section h1 {
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--secondairy-medium-gray);
  width: 99%;
  text-align: right;
}

/*  Generic  */

.disabled {
  pointer-events: none;
}

.hide {
  display: none !important;
}

.flex {
  display: flex;
  align-items: center;
}

.flex--top {
  display: flex;
  align-items: first baseline;
  gap: 7.5px;
}

.flex--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-spread {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex--vert {
  display: flex;
  flex-direction: column;
}

.red-border {
  border: var(--red) solid 2px !important;
  border-radius: 5px;
}

.half {
  width: 50% !important;
}

.no-border {
  border: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

/*  Tables  */

.table {
  width: 100%;
  /* min-width: 1040px; */
  font-size: 0.75rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
}

.table__rows_container--scroll-x {
  overflow: scroll;
  overflow-x: auto;
  height: 100%;
}

.table__rows_container {
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
}

.table__header--field {
  font-weight: 700;
  padding: 5px;
  text-align: left;
  word-break: normal;
}

.table__row--field {
  font-weight: 300;
  align-self: center;
  padding: 5px;
  word-break: break-all;
}

.table__header-5 {
  display: grid;
  grid-template: 35px / repeat(5, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-5 {
  display: grid;
  grid-template: 35px / repeat(5, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.75rem;
}

.table__header-4 {
  display: grid;
  grid-template: 35px / repeat(5, 1fr);
  width: calc(100% - 1em);
  font-weight: 500;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 0.75rem;
}

.table__row-4 {
  display: grid;
  grid-template: 35px / repeat(5, 1fr);
  width: 100%;
  padding: 7.5px 5px;
  border-radius: 6px;
  font-size: 0.75rem;
}

.table__row-5:nth-child(even),
.table__row-4:nth-child(even),
.table__row-3:nth-child(even),
.table__row-2:nth-child(even)
{
  background-color: var(--secondairy-ultra-light-gray);
}

/*  Buttons  */

.header_buttons {
  position: absolute;
  left: 0;
  display: flex;
  gap: 10px;
}

.primary-button-header--right {
  position: absolute;
  background-color: var(--primary-color-light);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 15px;
  font-size: 0.7rem;
  font-weight: 700;
  height: 30px;
  min-width: 120px;
  box-shadow: var(--border-shadow);
  cursor: pointer;
  z-index: 100;
  right: 15px;
  bottom: 0px;
}

.primary-button-header--right.red {
  background-color: var(--light-red);
}

.primary-button-header {
  position: relative;
  background-color: var(--primary-color-light);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px 15px;
  font-size: 0.7rem;
  font-weight: 700;
  height: 30px;
  min-width: 120px;
  box-shadow: var(--border-shadow);
  cursor: pointer;
  z-index: 100;
}

.primary-button-header.red {
  background-color: var(--light-red);
}

.back_button {
  position: absolute;
  top: 15px;

  display: flex;
  align-items: center;
  font-size: 0.6rem;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  cursor: pointer;
  z-index: 100;
  opacity: 0.75;
}

.back_button:hover {
  color: var(--primary-green);
}

.back_button svg {
  margin-right: 5px;
  width: 18px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12.5px;
  width: 100%;
  height: 80px;
}

.pagination .pagination__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 5px;
  color: var(--secondairy-medium-gray);
  font-size: 2rem;
  border: solid 1px var(--secondairy-medium-gray);
  cursor: pointer;
}

.pagination .pagination__btn:hover {
  background-color: var(--secondairy-light-gray);
}

.pagination .pagination__btn.grayed-out {
  color: var(--secondairy-light-medium-gray);
  border: solid 1px var(--secondairy-light-medium-gray);
  cursor: not-allowed;
}


.floating__button--bottom-right {
  position: absolute;
  bottom: 50px;
  /* left: 92.5vw; */
  right: 50px;
  z-index: 100;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: none;
  box-shadow: var(--border-shadow-float);
  background-color: var(--primary-color-light);
  opacity: 1;
  cursor: pointer;
}

#add--btn::after {
  content: "";
  position: absolute;
  top: 12.5px;
  left: calc(50% - 1px);
  width: 2px;
  height: 15px;
  background-color: var(--primary-black);
  cursor: pointer;
}

#add--btn::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 12.5px;
  width: 15px;
  height: 2px;
  background-color: var(--primary-black);
  cursor: pointer;
}

.secondairy-button {
  background-color: var(--secondairy-light-gray);
  border: none;
  border-radius: 5px;
  padding: 7.5px;
  font-size: 0.7rem;
  cursor: pointer;

  height: 30px;
  margin-top: 5px;
}

.more--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  background-color: var(--secondairy-ultra-light-gray);
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 7.5px;
  margin: 10px 0;
  font-size: 1.5rem;
  color: var(--secondairy-medium-gray);
  font-weight: 400;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.more--btn:hover {
  background-color: var(--secondairy-light-gray);
}

.options-button-header {
  position: relative;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  width: auto;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
}

.options-button-header.big {
  width: 250px;
}
.options-button-header.medium {
  width: 150px;
}

.options-button-header svg {
  color: var(--secondairy-light-medium-gray);
  font-size: 1.5rem;
}

.options-button-header:hover {
  background-color: var(--secondairy-light-gray);
}

.action-button-header {
  position: relative;
  background-color: white;
  box-shadow: var(--border-shadow-min);
  border: var(--secondairy-light-medium-gray) solid 1px;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 600;
  height: 30px;
  min-width: 100px;
  width: auto;
  cursor: pointer;
  text-transform: uppercase;
  background-color: var(--secondairy-light-gray);
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
}

.action-button-header.red {
  background-color: var(--light-red);
  color: var(--dark-red);
  border: var(--dark-red) solid 1px;
}

.list-button {
  position: relative;
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-gray);
  padding: 7.5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  color: var(--secondairy-medium-gray);
}

.list-button svg {
  font-size: 1.5rem;
}

.list-button:hover {
  background-color: var(--secondairy-light-gray);
}

.icon-button {
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-medium-gray);
  color: var(--secondairy-medium-gray);
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.icon-button.red {
  background-color: var(--ultra-light-red);
  border: 1px solid var(--dark-red);
  color: var(--dark-red);
}

/*  Input fields & Dropdowns  */

.input_error {
  font-size: 0.65rem;
  color: var(--red);
  padding: 0 5px 15px 5px;
}

.input_field {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
  width: 100%;
  margin-bottom: 7.5px;
}

.input_field__label {
  margin-left: 2.5px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 0.7rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input_field__label--small {
  font-size: 0.6rem;
}

.input_field__input_box--text {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  width: 100%;
  min-height: 30px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
}

.input_field__input_box--text.price {
  position: relative;
  padding-left: 20px;
}

.input_field__input_box--text.price::before {
  content: "€";
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondairy-medium-gray);
}

.input_field__input_box--text-area {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  width: 100%;
  min-height: 120px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
}

.input_field__input_box--text-area:focus {
  border: var(--primary-color-light) solid 1.5px;
  outline: none;
}

.input_field__input_box--text::placeholder {
  color: var(--secondairy-light-medium-gray);
  font-style: italic;
}

.input_field__input_box--text:focus {
  border: var(--primary-color-light) solid 1.5px;
  outline: none;
}

.input_field__dropdown {
  position: absolute;
  top: 45px;
  width: 100%;
  list-style: none;
  background-color: var(--secondairy-ultra-light-gray);
  max-height: 0px;
  display: none;
  pointer-events: none;
  /* padding: 3px 0px; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 500;
  border: var(--secondairy-light-gray) solid 1px;
  border-top: none;
  overflow-y: auto;
}

.input_field__dropdown::-webkit-scrollbar {
  width: 0.5rem;
}

.input_field__dropdown.active {
  max-height: 300px;
  height: auto;
  display: inline;
  pointer-events: all;
  transition: all 0.5s ease-in-out;
}

.input_field__dropdown li {
  color: var(--secondairy-medium-gray);
  padding: 8px 10px;
  font-size: 0.65rem;
  cursor: pointer;
}

.input_field__dropdown li:hover {
  background-color: white;
  color: black;
}

.input_field__dropdown.inherit {
  position: inherit;
}


.input_field__input_box--text--dropdown {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
  cursor: pointer;
  height: 30px;
}

.input_field__input_box--text--dropdown p {
  font-weight: 300;
  font-style: italic;
}

.input_field__input_box--text--dropdown svg {
  margin-left: auto;
  height: 120%;
  color: var(--secondairy-medium-gray);
}

.input_field__input_box--text.filter {
  cursor: pointer;
  height: 40px;
  box-shadow: var(--border-shadow-min);
  background-color: white;
  border: none;
  height: 30px;
  margin-top: 0;
}

.input_field--combo {
  display: flex;
  width: 100%;
  gap: 5px;
}

.input_field--combo-5050 {
  display: flex;
  width: 100%;
}

.input_field--combo-5050--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}

.input_field--combo-5050 .input_field {
  width: 50%;
}

.input_field--combo-5050 .input_field:first-child {
  margin-right: 5px;
}

.input_field--combo-7030 {
  display: flex;
  width: 100%;
}

.input_field--combo-7030 .input_field:first-child {
  margin-right: 5px;
  width: 70%;
}

.input_field--combo-7030 .input_field:nth-child(2) {
  width: 30%;
}

.input_field--combo-3070 {
  display: flex;
  width: 100%;
}

.input_field--combo-3070 .input_field:first-child {
  margin-right: 5px;
  width: 30%;
}

.input_field--combo-3070 .input_field:nth-child(2) {
  width: 70%;
}

.input_field--combo-grid {
  position: relative;
  display: grid;
  grid-template: 1fr / fit-content(100%) auto;
  gap: 5px;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 7.5px;
  padding: 5px;
  margin-bottom: 5px;
}

.input_field--combo-grid .input_field__label {
  display: flex;
  align-items: center;
}

.input_field--combo-grid .input_field__input_box--text {
  border: none;
  padding: 12.5px 5px;
  color: var(--secondairy-medium-gray);
  font-size: 0.8rem;
}

.input_field--combo-grid .input_field__input_box--text:focus {
  outline: none;
  background-color: var(--secondairy-ultra-light-gray);
}

.input_field--combo-grid .MuiInputBase-root {
  height: auto !important;
}

.input_field--combo-grid .MuiInputBase-input {
  color: var(--secondairy-medium-gray) !important;
  font-size: 0.70rem !important;
  font-weight: 400;
  padding: 12.5px 5px !important;
  border: none !important;
}

.input_field--combo-grid .MuiInputBase-input::placeholder {
  opacity: 1 !important;
  font-weight: 300;
}

.input_field--combo-grid .MuiInputBase-input:focus {
  opacity: 1 !important;
  font-weight: 300;
  background-color: var(--secondairy-ultra-light-gray);
}

.input_field--combo-grid .date-picker .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 5px;
}

.input_field--combo-grid.vertical {
  grid-template: auto 1fr / 1fr;
  border: none;
  padding: 0;
  margin-bottom: 0;
}

.input_field--combo-grid.vertical .input_field__label {
  font-size: 0.8rem;
  color: var(--secondairy-medium-gray);
}

.input_field--combo-grid.vertical .input-with-euro {
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 7.5px;
  padding: 5px;
}

.input_field--simple {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  width: 100%;
  gap: 10px;
}

.input_field--simple .input_field__label {
  width: 100%;
  font-size: 0.8rem;
  overflow: visible;
  word-wrap: break-word;
  white-space: normal;
}

.input_field--simple .input_field__input_box--text {
  font-size: 0.8rem;
  border-color: transparent;
}

.input_field--simple .input_field__input_box--text-area {
  border: none;
}

.input_field--simple.edit .input_field__input_box--text {
  border: var(--secondairy-light-gray) solid 1.5px;
}

.input_field--simple.edit .input_field__input_box--text-area {
  border: var(--secondairy-light-gray) solid 1.5px;
}

.search-bar--list {
  font-size: 0.8rem;
  color: var(--secondairy-medium-gray);
  border: none;
  border-bottom: var(--secondairy-light-gray) solid 1px;
  padding: 12.5px 5px;
}

.search-bar--list:focus {
  outline: none;
  border-bottom: var(--primary-color-light) solid 1px;
}

.search-bar--list::placeholder {
  color: var(--secondairy-light-medium-gray);
}

.search-bar {
  background-color: white;
  box-shadow: var(--border-shadow-min);
  border: none;
  border-radius: 7.5px;
  padding: 7.5px;
  font-size: 0.6rem;
  font-weight: 500;
  height: 30px;
  width: 25%;
  cursor: pointer;
  color: var(--secondairy-medium-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
}

.search-bar svg{
  font-size: 1rem;
}

.search-bar.dropdown {
  box-shadow: none;
  border: none;
  border-radius: 0;
  width: 100%;
  cursor: text;
  border-bottom: var(--secondairy-light-gray) solid 1px;
}

.search-bar.dropdown:focus {
  outline: none;
}

.MuiInputBase-root {
  height: 30px;
}

.MuiInputBase-input {
  color: var(--secondairy-medium-gray) !important;
  font-size: 0.70rem !important;
  font-weight: 400;
  padding-left: 7.5px !important;
}

.MuiInputBase-input::placeholder {
  opacity: 1 !important;
  font-weight: 300;
}

.date-picker .MuiOutlinedInput-notchedOutline {
  border: var(--secondairy-light-gray) solid 1.5px !important;
  border-radius: 5px;
}

/*  Placeholders & Loading */

.skeleton-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton-loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
  animation: shimmer 1.5s infinite;
}

.loading_spinner--circle {
  display: flex;
  align-items: center;
}

.loading_spinner--circle > span{
  height: 20px !important;
  width: 20px !important;
}

.loading__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: var(--secondairy-light-medium-gray);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
