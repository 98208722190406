#body-contacts {
  display: flex;
  flex-direction: column;
}

#body-contacts .flex {
  gap: 5px;
}

#new-contact-form {
  width: 100%;
  height: 65%;
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  gap: 10px;
}

#all-contacts-table {
  width: 100%;
  height: 35%;
}

#body-contacts .table__header-5 {
  grid-template: auto / 40px 0.8fr 0.8fr 0.8fr 2fr;
}

#body-contacts .table__row-5 {
  grid-template: 35px / 40px 0.8fr 0.8fr 0.8fr 2fr;
  cursor: pointer;
}

#body-contacts .table__header--field {
  cursor: pointer;
}