.label {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 5px 22.5px;
  background-color: var(--secondairy-light-gray) !important;
}

.label h4 {
  font-size: 0.75rem;
  margin-left: 5px;
}

.label .ball {
  position: absolute;
  height: 8px;
  width: 8px !important;
  left: 7.5px;
  border-radius: 50px;
  background-color: var(--secondairy-medium-gray) !important;
}

.label.green {
  background-color: var(--light-green) !important;
}

.label.green h4 {
  color: var(--dark-green) !important;
}

.label .ball.green {
  background-color: var(--green) !important;
}

.label .ball.green.bright {
  border: 2px solid var(--green) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--green) !important;
}

.label.blue {
  background-color: var(--light-blue) !important;
}

.label.blue h4 {
  color: var(--blue) !important;
}

.label .ball.blue {
  background-color: var(--blue) !important;
}

.label .ball.blue.bright {
  border: 2px solid var(--blue) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--blue) !important;
}

.label.gray {
  background-color: var(--secondairy-light-gray) !important;
}

.label.gray h4 {
  color: var(--secondairy-medium-gray) !important;
}

.label .ball.gray {
  background-color: var(--secondairy-medium-gray) !important;
}

.label .ball.gray.bright {
  border: 2px solid var(--secondairy-medium-gray) !important;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--secondairy-medium-gray) !important;
}

.label.red {
  background-color: var(--light-red) !important;
}

.label.red h4 {
  color: var(--dark-red) !important;
}

.label .ball.red {
  background-color: var(--red) !important;
}

.label .ball.red.bright {
  border: 2px solid #ff0000;
  border-radius: 10px;
  box-shadow: 0 0 5px #ff0000;
}

.label.brown {
  background-color: var(--light-brown) !important;
}

.label.brown h4 {
  color: var(--dark-brown) !important;
}

.label .ball.brown {
  background-color: var(--dark-brown) !important;
}

.label .ball.brown.bright {
  border: 2px solid var(--dark-brown);
  border-radius: 10px;
  box-shadow: 0 0 5px var(--dark-brown);
}

.label.orange {
  background-color: var(--light-orange) !important;
}

.label.orange h4 {
  color: var(--dark-orange) !important;
}

.label .ball.orange {
  background-color: var(--dark-orange) !important;
}

.label .ball.orange.bright {
  border: 2px solid var(--dark-orange);
  border-radius: 10px;
  box-shadow: 0 0 5px var(--dark-orange);
}
