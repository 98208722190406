.notification-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .notification-popup {  
    text-align: center;
    background-color: var(--primary-black);
    border: 1px solid var(--secondairy-medium-gray);
    padding: 20px 30px;
    border-radius: 5px;
    min-width: 300px;
    max-width: 90%;
    color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }

  .notification-popup p {
    font-size: 1.1rem;
    font-weight: 300;
    padding: 20px 0;
  }
  
  .notification-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .notification-button {
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    color: var(--primary-black);
    cursor: pointer;
    font-weight: 500;
    min-width: 80px;
    transition: opacity 0.2s;
  }
  
  .notification-button:hover {
    opacity: 0.9;
  }