.popup-input {
  position: relative;
  display: flex;
  justify-content: center;
}

.popup-input__trigger {
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--secondairy-light-medium-gray);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding: 5px;
  height: 100%;
}

.popup-input__trigger.has-content {
  color: var(--secondairy-medium-gray);
}

.popup-input__has-content {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: var(--primary-red);
  border-radius: 50%;
}

.popup-input__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-input__popup {
  background-color: var(--primary-black);
  border: 1px solid var(--secondairy-medium-gray);
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 90%;
  color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.popup-input__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-input__header .title {
  color: var(--secondairy-medium-gray);
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
}

.popup-input__close-button {
  background: transparent;
  border: none;
  color: var(--secondairy-medium-gray);
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.popup-input__fields {
  margin-bottom: 20px;
}

.popup-input__field {
  margin-bottom: 15px;
}

.popup-input__field label {
  display: block;
  margin-bottom: 5px;
  color: var(--secondairy-light-medium-gray);
  font-size: 0.8rem;
}

.popup-input__field textarea {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  font-size: 0.8rem;
  background-color: var(--secondairy-dark-gray);
  outline: none;
  border: none;
  color: var(--secondairy-light-medium-gray);
  resize: vertical;
  min-height: 200px;
}

.popup-input__field textarea::placeholder {
  color: var(--secondairy-medium-gray);
}

.popup-input__actions {
  display: flex;
  justify-content: flex-end;
}

.popup-input__save-button {
  background-color: var(--primary-red);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
}

.popup-input__save-button:hover {
  background-color: var(--primary-color);
} 