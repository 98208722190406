.popup__container.building-detail {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 300;
    border-radius: 0;
    backdrop-filter: blur(5px);
    padding-left: 200px;
}

.popup__container.building-detail .input_field__label {
    font-size: 0.9rem;
}

.popup__container.building-detail .popup__box {
    position: relative;
    width: 95%;
    height: 80%;
    overflow: hidden;
}

.popup__container.building-detail .popout_body {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.popup__container.building-detail .popout_body .more--btn {
  margin: 0;
  margin-bottom: 20px;
}

.popup__container.building-detail .popout_window__header {
    position: relative;
}

.popup__container.building-detail .popout_window__header h3 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-variant: small-caps;
    color: var(--secondairy-medium-gray);
}

#popout-body-building .details_container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    gap: 15px;
    padding: 15px 0 0 0;
  }
  
  #popout-body-building .details_container.small {
    grid-template: 1fr / 1fr;
  }
  
  #popout-body-building .details_container .panel {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  #popout-body-building .details_container .body__section {
    box-shadow: none;
    height: 100%;
    padding: 15px 0 0 0;
    margin: 0;
  }
  
  #popout-body-building .details_container .form__section {
    margin: 0;
    height: 100%;
  
    border: var(--secondairy-light-gray) solid 1px;
    border-radius: 10px;
    overflow-y: scroll;

    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .popup__container.building-detail .popup__box .body__section h2 {
    position: absolute;
    top: 0;
    font-size: 1rem;
    text-align: center;
}

.popup__container.building-detail .popup__box .body__section .form__section--content {
    overflow: scroll;
    flex-grow: 1;
    margin: 30px 15px;
}

.popup__container.building-detail #popup-building-notes-create, 
.popup__container.building-detail #popup-building-notes-detail {
    width: 100%;
    height: 100%;
    padding: 10px 0 0 0;
}