#popup-transaction {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 300;
    border-radius: 0;
    backdrop-filter: blur(5px);
}

#popup-transaction .popup__box {
    position: relative;
    width: 80%;
    height: 80%;
    position: relative;
    border: 1px solid var(--secondairy-light-medium-gray);
    overflow: hidden;
}

#popup-transaction .popout_body {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#popup-transaction .popout_body__content_container {
    display: grid;
    gap: 20px;
    height: 100%;
    min-height: 0;
    overflow: hidden;
}

#popup-transaction .popout_body__content_container.properties {
    grid-template-columns: 1fr;
}

#popup-transaction .popout_body__content_container.details {
    grid-template-columns: 1fr 1fr;
}

#popup-transaction .panel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    height: 100%;
    min-width: 0;
    width: 100%;
}

#popup-transaction .search-bar--list-container {
    position: relative;
}

#popup-transaction .search-bar--list {
    width: 100%;
}

#popup-transaction .search-bar--clear-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--secondairy-medium-gray);
    font-size: 1.2rem;
    font-weight: 300;
}

#popup-transaction .properties_list,
#popup-transaction .selected_properties_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

#popup-transaction .properties_list {
    overflow-y: auto;
}

#popup-transaction .property-item,
#popup-transaction .selected_property_item {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 40px;
    padding: 10px;
    font-size: 0.8rem;
    color: var(--secondairy-medium-gray);
    cursor: pointer;

    transition: background-color 0.2s ease-in-out;
}

#popup-transaction .selected_property_item {
    background-color: var(--secondairy-light-gray);
}

#popup-transaction .property-item>.property_item_value,
#popup-transaction .selected_property_item>.property_item_value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#popup-transaction .property_item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: end;
    align-self: center;
    width: 35px;
    height: 35px;
    background-color: var(--ultra-light-green);
    border: 1px solid var(--dark-green);
    color: var(--dark-green);
    border-radius: 7.5px;
    font-size: 1.2rem;
    font-weight: 200;
    user-select: none;
}

#popup-transaction .property_item__icon.min {
    background-color: var(--ultra-light-red);
    border: 1px solid var(--dark-red);
    color: var(--dark-red);
}

#popup-transaction .property-item:nth-child(even) {
    background-color: var(--secondairy-ultra-light-gray);
}

#popup-transaction .selected_properties_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--secondairy-light-medium-gray);
    padding: 10px;
    border-radius: 7.5px;
    overflow-y: auto;
    flex: 1;
    min-height: 0;
}

#popup-transaction .selected_properties_list h3 {
    font-size: 0.9rem;
    font-weight: 400;
}

#popup-transaction .selected_contacts_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    height: 100%;
    min-height: 0;
}

#popup-transaction .sellers_list,
#popup-transaction .buyers_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--secondairy-light-medium-gray);
    padding: 10px;
    border-radius: 7.5px;
    overflow-y: auto;
    flex: 1;
    min-height: 0;
}

#popup-transaction .sellers_list h3,
#popup-transaction .buyers_list h3 {
    font-size: 0.9rem;
    font-weight: 400;
}

#popup-transaction .contacts_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    overflow-y: auto;
}

#popup-transaction .contact-item,
#popup-transaction .selected_contact_item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 40px;
    padding: 10px;
    font-size: 0.8rem;
    color: var(--secondairy-medium-gray);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

#popup-transaction .contact-item:nth-child(even) {
    background-color: var(--secondairy-ultra-light-gray);
}

#popup-transaction .selected_contact_item {
    background-color: var(--secondairy-light-gray);
}

#popup-transaction .contact_item_value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#popup-transaction .contact_item_inputs {
    display: flex;
    gap: 10px;
    align-items: center;
}

#popup-transaction .contact_item_inputs input {
    width: 70px;
    padding: 5px;
    border: 1px solid var(--secondairy-light-medium-gray);
    border-radius: 4px;
    font-size: 0.8rem;
    height: 100%;
}

#popup-transaction .contact_item_inputs select {
    padding: 5px;
    border: 1px solid var(--secondairy-light-medium-gray);
    border-radius: 4px;
    font-size: 0.8rem;
}

#popup-transaction .selected_contact_item {
    grid-template-columns: 1fr 2fr 40px;
    gap: 5px;
}

.contact_item_inputs {
    display: flex;
    gap: 5px;
    align-items: center;
}

.contact_item_inputs input {
    width: 60px;
    padding: 5px;
    border: 1px solid var(--secondairy-light-medium-gray);
    border-radius: 4px;
    font-size: 0.8rem;
}

.contact_item_inputs input:focus {
    outline: none;
}

.selected_contact_item {
    grid-template-columns: 1fr 1fr 2fr 40px;
}

@media screen and (max-width: 1280px) {
    #popup-transaction .popup__box {
        width: 95%;
        height: 95%;
    }
}

.percentage-input-container {
    position: relative;
    display: inline-block;
    height: 100%;
}

.percentage-input-container input {
    height: 100%;
    padding-right: 20px;
    /* Make room for the suffix */
}

.percentage-suffix {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    /* Makes the suffix non-interactive */
    color: var(--text-color);
}

/* Input with euro sign styling for all price inputs */
.input-with-euro {
    position: relative;
    display: flex;
    align-items: center;
}

.euro-sign {
    position: absolute;
    left: 8px;
    color: var(--secondairy-medium-gray);
    z-index: 1;
    pointer-events: none;
}

.input-with-euro input {
    padding-left: 25px !important;
    width: 100%;
    font-weight: 400;
    box-sizing: border-box;
}

/* Specific styling for disabled input */
.input-with-euro input:disabled {
    background-color: var(--secondairy-ultra-light-gray);
    opacity: 1;
    font-weight: 400;
}

.fraction-input-container {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 8px;
}

.fraction-input {
    width: 50px;
    text-align: center;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.fraction-divider {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 2px;
}

.percentage-display {
    color: #666;
    font-size: 0.9em;
    margin-left: 8px;
}

.property-price-details {
    background: var(--secondairy-ultra-light-gray);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
}

.property-price-details .price-inputs {
    display: flex;
    gap: 15px;
    flex-wrap: nowrap;
}

.property-price-details .input_field--combo-grid {
    flex: 1;
    min-width: 0;
    background: white;
}

.property-price-details .input-with-euro {
    position: relative;
    display: flex;
    align-items: center;
}

.property-price-details .input-with-euro input {
    padding-left: 25px;
    width: 100%;
    padding: 8px;
    padding-left: 25px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.property-price-details .input_field__label {
    color: var(--secondairy-medium-gray);
    font-size: 0.8rem;
    margin-bottom: 5px;
    display: block;
    display: flex;
    align-items: center;
}

.property-price-details .total-row {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid var(--secondairy-light-medium-gray);
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    color: var(--secondairy-medium-gray);
}

.price-input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.price-input-group label {
    font-size: 0.8rem;
    color: var(--secondairy-medium-gray);
}

.price-summary {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;
    border-top: 1px solid var(--secondairy-light-medium-gray);
    font-size: 0.9rem;
}

.price-summary .total {
    font-weight: 500;
    color: var(--dark-green);
    margin-top: 5px;
}

.price-summary .subtotal,
.price-summary .vat {
    color: var(--secondairy-medium-gray);
}