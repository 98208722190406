.content_editor__container {
    width: 100%;
    height: fit-content;
}

.custom-quill {
    height: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 7.5px;
}

.custom-quill .ql-toolbar {
    height: auto;
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
    border-color: var(--secondairy-light-medium-gray);
}

.custom-quill .ql-container {
    flex-grow: 1;
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
    border-color: var(--secondairy-light-medium-gray);
}

.custom-quill .ql-editor {
    height: 100%;
    overflow-y: auto;
}