#body-companies {
    display: flex;
    flex-direction: column;
  }
  
  #body-companies .flex {
    gap: 5px;
  }
  
  #new-company-form {
    width: 100%;
    height: 65%;
    display: grid;
    grid-template: auto / repeat(3, 1fr);
    gap: 10px;
  }
  
  #all-companies-table {
    width: 100%;
    height: 35%;
  }
  
  #body-companies .table__header-5 {
    grid-template: auto / 40px 0.8fr 0.8fr 0.8fr 2fr;
  }
  
  #body-companies .table__row-5 {
    grid-template: 35px / 40px 0.8fr 0.8fr 0.8fr 2fr;
    cursor: pointer;
  }
  
  #body-companies .table__header--field {
    cursor: pointer;
  }