.template__aside {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  width: 12.5vw;
  max-width: 220px;
  padding: 2.5px;
  background-color: var(--primary-black);
  min-height: 100vh;
  transition: all 0.255s ease-in-out;
  z-index: 200;
}

.template__aside img {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

.primary__menu {

  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 15px 10px;
}

.secondairy__menu {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 15px 10px;
}

.menu {
  position: absolute;
  width: 95%;
  height: 84%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  bottom: 15px;
}

.menu__item {
  display: grid;
  grid-template-columns: 1rem auto;
  align-items: center;
  cursor: pointer;
  color: white;
  padding: 7.5px 0px;
  width: 80%;
}

.menu__item:hover {
  color: var(--primary-color-light);
}

.menu__item svg {
  cursor: pointer;
  font-size: 1rem;
}

.menu__item p {
  font-size: 1rem;
  margin-left: 15px;
}

.menu hr {
  width: 80%;
  margin: 10px 0px;
  background-color: white;
  border: solid 1px white;
}

.menu-Slider {
  position: absolute;
  z-index: 50;
  height: 20px;
  width: 20px;
  border: none;
  background-color: var(--primary-color-light);
  border-radius: 50px;
  right: -7.5px;
  top: calc(15vh - 10px);
}

.menu-Slider svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
  z-index: 200;
}

.sub-menu {
  opacity: 0;
  pointer-events: none;
  max-height: 0px;
  transition: max-height 0.6s , opacity 0.2s ease-in;
  width: 100%;
}

.sub-menu.active {
  pointer-events: all;
  display: block;
  max-height: 300px;
  opacity: 1;
  border: none;
}

.sub-menu li {
  border-style: none;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 7.5px 0 7.5px 30px;
  cursor: pointer;
  color: white;
  list-style: none;
  width: 100%;
  font-size: 0.75rem;
}

.sub-menu li:hover {
  background-color: var(--secondairy-medium-gray);
}

/*  MINI MENU  */

.template__aside.mini {
  min-width: 0;
  width: 65px;
}

.template__aside.mini img {
  opacity: 0;
}

.template__aside.mini .menu__item p {
  display: none;
}

.template__aside.mini .menu__item svg {
  margin: 0 auto;
}

.template__aside.mini .menu-Slider svg {
  transform: rotate(180deg);
}