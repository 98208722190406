/* Purchase Section */

#popout-body-unit #unit-purchase-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
  }
  
  #popout-body-unit #unit-purchase-section .top_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  #popout-body-unit #unit-purchase-section .purchase-order-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    min-height: 50px;
    align-items: flex-start;
  }
  
  #popout-body-unit #unit-purchase-section h2 {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 10px;
    margin-left: 0;
    height: auto;
  }
  
  #popout-body-unit #unit-purchase-section .top_container .pocs-section {
    position: relative;
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  #popout-body-unit #unit-purchase-section .top_container .pocs-section .poc-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    overflow-y: auto;
    flex: 1;
    scrollbar-width: thin; /* For Firefox */
    &::-webkit-scrollbar {
      width: 8px;
      display: block;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  
  #popout-body-unit #unit-purchase-section .top_container .pocs-section .add-poc-button {
    width: 25px;
    height: 25px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-medium-gray);
    border-radius: 4px;
    cursor: pointer;
    color: var(--secondairy-medium-gray);
    font-weight: 500;
  
    transition: background-color 0.2s;
  }
  
  #popout-body-unit #unit-purchase-section .top_container .pocs-section .add-poc-button:hover {
    background-color: var(--secondairy-light-gray);
  }
  
  #popout-body-unit #unit-purchase-section .top_container .pocs-section .poc-item {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    width: 100%;
    font-size: 0.75rem;
    padding: 7.5px 10px;
    padding-left: 0px;
    cursor: pointer;
    color: var(--secondairy-medium-gray);
  
    &:nth-child(even) {
      background-color: var(--secondairy-ultra-light-gray);
    }
  }
  
  #popout-body-unit #unit-purchase-section .poc-item > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  #popout-body-unit #unit-purchase-section .poc-item > div:first-child {
    cursor: pointer;
  }
  
  #popout-body-unit #unit-purchase-section .poc-item > div:not(:first-child) {
    cursor: copy;
  }
  
  #popout-body-unit #unit-purchase-section .bottom_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    overflow: hidden;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container {
    flex: 1;
    overflow-y: auto;
    padding-right: 10px;
    min-height: 0;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .po_add_button {
    width: fit-content;
    font-size: 0.8rem;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-medium-gray);
    border-radius: 4px;
    cursor: pointer;
    color: var(--secondairy-medium-gray);
    font-weight: 500;
    margin-top: 15px;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .po_add_button:hover {
    background-color: var(--secondairy-light-gray);
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_categories--list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_categories--list .product_category--item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 7.5px;
    padding-bottom: 7.55px;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_categories--list .product_category--title {
    font-size: 0.8rem;
    color: var(--secondairy-medium-gray);
    font-weight: 500;
    margin-bottom: 7.5px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--secondairy-light-medium-gray);
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_categories--list .product_category--buttons {
    display: flex;
    gap: 5px;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_categories--order_line__header {
    display: grid;
    grid-template: 60px / 250px repeat(3, 160px) 80px 100px repeat(5, 160px) 80px 100px 200px 60px;
    column-gap: 10px;
    font-size: 0.8rem;
    color: var(--secondairy-medium-gray);
    font-weight: 500;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_categories--order_line__header_name {
    padding: 5px;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_category--order_line {
    display: grid;
    grid-template: 30px / 250px repeat(3, 160px) 80px 100px repeat(5, 160px) 80px 100px 200px 60px;
    margin-bottom: 12.5px;
    column-gap: 10px;
    font-size: 0.8rem;
    color: var(--secondairy-medium-gray);
    border-left: 3px solid transparent;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_category--order_line.indented {
    grid-template: 30px / 228px repeat(3, 160px) 80px 100px repeat(5, 160px) 80px 100px 200px 60px;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_category--order_line .product_category--order_line_name {
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px;
    width: 100%;
    justify-content: space-between;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_category--order_line .product_category--order_line_name input {
    border: 1.5px solid var(--secondairy-light-gray);
    border-radius: 5px;
    padding: 5px;
    height: 30px;
    width: 100%;
    color: var(--secondairy-medium-gray);
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_category--order_line .product_category--order_line_name input:focus {
    outline: none;
  }
  
  #popout-body-unit #unit-purchase-section .purchase_details--container .product_category--order_line .product_category--order_line_name.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #unit-purchase-section .product_category--order_line .product_category--order_line_name .checkbox--box__container {
    margin: 0;
  }
  
  #unit-purchase-section .product_group_container {
    background-color: var(--secondairy-ultra-light-gray);
  }
  
  #unit-purchase-section .product_category--order_line.group-header {
    background-color: var(--secondairy-ultra-light-gray);
    cursor: pointer;
    border-left: 3px solid transparent;
  }
  
  #unit-purchase-section .product_category--order_line.indented {
    margin-left: 20px;
    border-left: 2px solid var(--secondairy-light-gray);
  }
  
  #unit-purchase-section #group-header-arrow {
    right: 0;
  }
  
  #popout-body-unit #unit-purchase-section .product_category--scrollable-area {
    overflow-x: auto;
    max-height: 500px; /* Adjust this value based on your needs */
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  
  #popout-body-unit #unit-purchase-section .product_categories--order_line__header {
    position: sticky;
    top: 0;
    background-color: white; /* Match your app's background color */
    z-index: 1;
    min-width: max-content;
  }
  
  #popout-body-unit #unit-purchase-section .product_category--order_lines {
    min-width: max-content;
  }

  #unit-purchase-section .alternative-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    font-size: 0.75rem;
    background-color: var(--ultra-light-blue);
    border: 1px solid var(--light-blue);
    border-radius: 4px;
    cursor: pointer;
    color: var(--blue);

    transition: background-color 0.2s;
  }

  #unit-purchase-section .alternative-button svg {
    font-size: 0.9rem;
  }

  #unit-purchase-section .alternative-button:hover {
    background-color: var(--light-blue);
  }

  /* Alternative options container and buttons */
  .alternative-options-container {
    margin: -5px 0 15px 20px;
    padding: 10px;
    background-color: var(--secondairy-ultra-light-gray);
    border-radius: 4px;
    border-left: 3px solid var(--secondairy-light-medium-gray);
  }

  .alternative-options {
    display: flex;
    gap: 10px;
  }

  .alternative-option {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
    padding: 5px;
    width: 120px;
    height: 40px;
    background-color: white;
    border: 1px solid var(--secondairy-light-medium-gray);
    border-radius: 4px;
    cursor: pointer;
    color: var(--secondairy-medium-gray);
    font-weight: 500;
    font-size: 0.8rem;
    transition: all 0.2s ease;
  }

  .alternative-option:hover {
    background-color: var(--secondairy-light-gray);
    color: var(--secondairy-dark-gray);
  }

  /* Style for alternative products in the list */
  #unit-purchase-section .product_category--order_line.alternative {
    border-left: 3px solid var(--light-blue) !important;
  }

  #unit-purchase-section .delete-button {
    color: #d32f2f;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }

  #unit-purchase-section .delete-button:hover {
    background-color: rgba(211, 47, 47, 0.1);
  }

  #unit-purchase-section .delete-button svg {
    font-size: 18px;
  }