#body-transactions {
    width: 100%;
    grid-template: 1fr / 1fr;
}

#body-transactions .table__header-5,
#body-transactions .table__row-5 {
    grid-template: auto / 60px 1fr 1fr 1fr 1fr;
}

#body-transactions .table__row-5 {
    display: grid;
    cursor: pointer;
    margin-bottom: 2.5px;
    height: fit-content;
}

#body-transactions .table__row-5.buy {
    background-color: var(--ultra-light-red);
    color: var(--dark-red);
}

#body-transactions .table__row-5.sell {
    background-color: var(--ultra-light-green);
    color: var(--dark-green);
}

#body-transactions .table__row-5.origin {
    background-color: var(--secondairy-ultra-light-gray);
    color: var(--secondairy-medium-gray);
}

#body-transactions .table__row--field {
    display: flex;
    align-items: center;
    height: fit-content;
}

#body-transactions .table__row--field--icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#body-transactions .table__row--field--icon svg {
    width: 30px;
    height: 30px;
}

#body-transactions .table__row--field--icon.buy svg,
#body-transactions .table__row--field--icon.buy svg path {
    stroke: var(--dark-green) !important;
}

#body-transactions .table__row--field--icon.sell svg,
#body-transactions .table__row--field--icon.sell svg path {
    stroke: var(--dark-red) !important;
}

#body-transactions .table__row--field--icon.origin svg,
#body-transactions .table__row--field--icon.origin svg path {
    stroke: var(--secondairy-light-medium-gray) !important;
}

#body-transactions .table__row--field--icon.origin svg {
    height: 25px;
    width: 25px;
}

.table__row--field.labels-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: fit-content;
}

.label-item {
    margin: 0 5px 5px 0;
    background-color: var(--secondairy-ultra-light-gray);
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    white-space: nowrap;
}

/* Modified selectors to work with parent row classes */
.table__row-5.buy .label-item {
    background-color: var(--light-red);
    color: var(--dark-red);
}

.table__row-5.sell .label-item {
    background-color: var(--light-green);
    color: var(--dark-green);
}

.table__row-5.origin .label-item {
    background-color: var(--secondairy-light-gray);
    color: var(--secondairy-medium-gray);
}