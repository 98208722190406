.file_dropper__container {
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.file_dropper__container .image_wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file_dropper__container .image_wrapper img {
  height: 100%;
}

.file_dropper__container .delete_button {
  position: absolute;
  bottom: -60px;
  left: -10px;
  width: calc(100% + 20px);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  height: 50px;
  cursor: pointer;
  background-color: var(--light-red);
  border: none;
  transition: transform 500ms ease-in-out;
}

.file_dropper__container .delete_button.visible {
  transform: translateY(-60px);
}

.file_dropper__container .loading_container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondairy-light-gray);
}

.file_dropper__container .ant-upload-wrapper svg {
  color: var(--primary-color-light);
}

.file_dropper__container .document_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.file_dropper__container .document_wrapper a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file_dropper__container .document_wrapper a img {
  height: 100px;
}

.file_dropper__container .document_wrapper a p {
  margin-top: 15px;
}