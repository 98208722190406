.note__container {
  display: grid;
  grid-template: 30px / 35px 70px 1fr;
  gap: 10px;
  margin-bottom: 10px;
  padding: 7.5px 15px;
  align-items: center;
  cursor: pointer;
  color: var(--secondairy-medium-gray);
  margin: 5px 0;
}

.note__container:nth-child(even) {
  background-color: var(--secondairy-ultra-light-gray);
}

.note__container .note_icon {
  display: flex;
  align-items: center;
  padding: 0;
}

.note__container .note_icon img {
  height: 30px;
}

.note__container .date_note {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
}

.note__container .note_text {
  font-size: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}