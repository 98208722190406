#body-dashboard .body_section:nth-child(1) {
    grid-row: span 2;
    grid-column: span 2;
}

#body-dashboard .body_section:nth-child(2) {
    grid-row: span 2;
    grid-column: span 1;
}

#body-dashboard .body_section:nth-child(3) {
    grid-row: span 2;
    grid-column: span 1;
}

#body-dashboard .body_section:nth-child(4) {
    grid-row: span 2;
    grid-column: span 1;
}

#body-dashboard .body_section:nth-child(5) {
    grid-row: span 2;
    grid-column: span 3;
}