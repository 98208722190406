.file_dropper__container.image-dropper {
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.file_dropper__container.image-dropper .ant-upload-wrapper svg {
  color: var(--primary-color-light);
  font-size: 2.8rem;
}

.file_dropper__container.image-dropper .project_images {
  min-height: 110px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 7.5px;
  margin-bottom: 10px;
  padding: 5px 0;
}

.file_dropper__container.image-dropper .project_images .project_image {
  position: relative;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  border: white 2px solid;
}

.file_dropper__container.image-dropper .project_images .project_image.main {
  border: yellow 2px solid;
}

.file_dropper__container.image-dropper .project_images .project_image.main svg {
  position: absolute;
  top: 5px;
  right: 5px;
  fill: yellow;
}

.file_dropper__container.image-dropper .project_images .project_image.selected {
  border: var(--primary-color) 2px solid;
}

.file_dropper__container.image-dropper .project_images .project_image #delete-image {
  position: absolute;
  font-size: 14px;
  right: -4px;
  top: -4px;
  padding: 2px;
  border-radius: 50%;
  background-color: black;
  color: white;
}

.file_dropper__container.image-dropper .project_images .project_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.file_dropper__container.image-dropper .project_images .loading_image {
  height: 100%;
  width: 100px;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
  border-radius: 5px
}

.file_dropper__container.image-dropper .project_images .loading_image::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.749), transparent);
  animation: loading 1.5s infinite;
}

/* Keyframes for skeleton loading animation */
@keyframes loading {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}