.slider {
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    cursor: pointer;
}

.slider__bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 2px;
    width: 25px;
    height: 12px;
    background-color: var(--secondairy-light-gray);
    border-radius: 20px;
}

.slider__bar.green {
    background-color: var(--primary-color-light);
}

.slider__ball {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50px;
}

.slider__ball.right {
    margin-left: auto;
}

.slider__text {
    font-size: 0.6rem;
    font-weight: 400;
}

.slider__text.h2 {
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--secondairy-medium-gray);
    text-transform: uppercase;
}

.slider.dark--mobile .slider__bar  {
    border: 1px solid var(--secondairy-light-gray-plus);
}

.slider.dark--mobile .slider__text {
    font-weight: 400;
    color: var(--secondairy-light-gray);
}

.slider.light--mobile {
    margin: 15px 0;
}

.slider.light--mobile .slider__bar  {
    border: 1px solid var(--secondairy-light-gray-plus);
}

.slider.light--mobile .slider__text {
    font-weight: 400;
    font-size: 1rem;
    color: var(--primary-black);
}