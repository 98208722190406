.dropdown__container-dropdown.classic {
  margin-bottom: 7.5px;
}

.dropdown__container-dropdown.classic .dropdown-input--text_field {
  font-style: normal;
}

.dropdown__container-dropdown.classic .dropdown-input--text_field.placeholder {
  font-style: italic;
  color: var(--secondairy-light-medium-gray);
}

.dropdown__container-dropdown .input_field__dropdown.classic {
  position: inherit;
}

.dropdown__container-dropdown .input_field__dropdown.classic .dropdown__item {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
}

.dropdown__container-dropdown .input_field__dropdown.classic .dropdown__item--single {
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}

.dropdown__container-dropdown .input_field__dropdown.classic .dropdown__item--single {
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}

.skeleton-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton-loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
  animation: shimmer 1.5s infinite;
}

.input_field--combo-grid .dropdown__container-dropdown {
  width: 100%;
  border: none;
  font-size: 0.8rem;
}

.input_field--combo-grid .dropdown__container-dropdown .input_field__input_box--text--dropdown {
  border: none;
  font-size: 0.8rem;
}

.input_field--combo-grid .dropdown__container-dropdown .dropdown-input--text_field.placeholder {
  font-style: normal;
  color: var(--secondairy-light-medium-gray);
  font-size: 0.8rem;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

