.body-loginpage {
  min-height: 100vh;
  background: var(--primary-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.body-loginpage img {
  position: absolute;
  top: 40px;
  width: 42.5vh;
}

.login-form {
  height: 35vh;
  width: 45vh;
  background-color: var(--secondairy-ultra-light-gray);
  padding: 40px 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-login {
  background-color: var(--primary-color);
  color: #fff;
  border-style: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 2vmin;
  height: 25%;
  width: 80%;
  margin-top: 10%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-login svg {
  vertical-align: middle;
}

.login-form input {
  background-color: var(--secondairy-light-gray);
  border-style: none;
  height: 25%;
  width: 80%;
  border-radius: 5px;

  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.5vmin;
  margin-bottom: 1.5%;
  color: var(--primary-black);
}

.login-form input:focus {
  outline: var(--primary-black) solid 1.5px;
}

.login-links {
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  cursor: pointer;
}

.login-links a {
  color: black;
  font-size: 1.5vmin;
}

.login-links a:hover {
  border-bottom: solid black 1px;
}
