.template__header {
  position: relative;

  height: 10vh;
  /* box-shadow: var(--border-shadow-min); */
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px 0px 20px;
  z-index: 51;
}

.template__header.hide {
  display: none;
  pointer-events: none;
}

.template__header h1 {
  font-size: 1.3em;
  margin-bottom: 10px;
}

.header--p {
  font-size: 0.75em;
  color: var(--secondairy-medium-gray);
  text-align: left;
  width: 22.5vw;
}

#header-title {
  margin-right: auto;
}