#body-product-detail {
  grid-template: 1fr / 1fr;
}

#body-product-detail #product-detail {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  gap: 10px;
}

#body-product-detail #product-detail #image_upload,
#body-product-detail #product-detail #prices-section {
  grid-column: span 2;
}

#body-product-detail #product-detail.has-expanded #image_upload.expanded,
#body-product-detail #product-detail.has-expanded #prices-section.expanded {
  grid-column: 1 / span 3 !important;
  grid-row: 1 / span 2;
}

#body-product-detail .form__section {
  position: relative;
  height: 100%;
  max-height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* display: flex;
  flex-direction: column; */
}

#body-product-detail .file__uploader_section {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#body-product-detail .form__section h3 {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--secondairy-medium-gray);
  cursor: pointer;
  z-index: 10;
  transition: color 0.2s ease;
}

#body-product-detail .form__section h3:hover {
  color: var(--primary-color);
}

#body-product-detail #product-detail-section {
  display: flex;
  gap: 2rem;
}

#body-product-detail .form__column {
  position: relative;
  flex: 1;
}

#body-product-detail .form__column h2 {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 0.75rem;
  color: var(--secondairy-medium-gray);
}

#body-product-detail .photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  height: 100%;
  padding: 1rem 0;
}

#body-product-detail .photo-grid-item {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: transform 0.2s;
  background-color: white;
}

#body-product-detail .photo-grid-item:hover {
  transform: scale(1.05);
}

#body-product-detail .photo-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#body-product-detail .add-photo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: 2rem;
  color: #666;
  border: 2px dashed #ddd;
}

#body-product-detail .add-photo-button:hover {
  background-color: #eee;
  color: #333;
}

#body-product-detail .image-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

#body-product-detail .image-viewer-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

#body-product-detail .image-viewer-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

#body-product-detail .close-button {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

#image_upload input[type="file"] {
  display: none;
}

#body-product-detail .delete-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  padding: 0;
}

#body-product-detail .delete-image-button svg {
  color: white;
  font-size: 16px;
}

#body-product-detail .delete-image-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

#body-product-detail .file-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 1rem 0;
}

#body-product-detail .file-list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
  min-height: 48px;
}

#body-product-detail .file-download-link {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  color: inherit;
  flex: 1;
}

#body-product-detail .file-name {
  font-size: 14px;
  width: 70%;
  word-wrap: break-word;
}

#body-product-detail .add-file-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background-color: #f5f5f5;
  color: #666;
  border: 2px dashed #ddd;
  height: 50px;
}

#body-product-detail .add-file-button:hover {
  background-color: #eee;
  color: #333;
}


#body-product-detail .add-file-button input {
  display: none;
}

#body-product-detail .delete-file-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 4px;
}

#body-product-detail .delete-file-button:hover {
  color: #ff4444;
}

#body-product-detail .prices-list {
  margin-bottom: 20px;
  max-height: 400px;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

#body-product-detail .prices-list h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--secondairy-medium-gray);
}

#body-product-detail .price-type-selector {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

#body-product-detail .price-type-selector label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

#body-product-detail .price-type-selector input[type="radio"] {
  margin: 0;
}

#body-product-detail .price-type-selector input[type="radio"]:checked {
  border-color: var(--secondairy-medium-gray);
}

#body-product-detail .price-type-selector label {
  font-size: 0.8rem;
}

#body-product-detail .price-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

#body-product-detail .price-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 10px;
  margin-bottom: 15px;
}

#body-product-detail .grid-item {
  position: relative;
}

/* Add styling for PopupDropdown components in the grid */
#body-product-detail .grid-item .popup-dropdown {
  width: 100%;
  height: 100%;
}

#body-product-detail .grid-item .popup-dropdown .popup-dropdown__selected {
  width: 100%;
  height: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
}

#body-product-detail .add-price-form {
  background-color: var(--secondairy-ultra-light-gray);
  padding: 20px;
  border-radius: 4px;
}

#body-product-detail .form-button-container {
  display: flex;
  justify-content: flex-start; /* Change to right-aligned */
  margin-top: 15px;
}

#body-product-detail .form-button-container .primary-button {
  min-width: 120px;
  padding: 10px 20px;
  cursor: pointer;
}

#body-product-detail .input-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;
}

#body-product-detail .input-row__item {
  flex: 1;
  background-color: white;
  border-radius: 4px;
  border: 1.5px solid var(--secondairy-light-gray);
}

#body-product-detail .prices-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

#body-product-detail .prices-table th,
#body-product-detail .prices-table td {
  padding: 8px;
  border-bottom: 1px solid var(--secondairy-light-gray);
  text-align: left;
  font-size: 0.8rem;
}

#body-product-detail .prices-table th {
  background-color: var(--secondairy-ultra-light-gray);
  font-weight: 500;
}

#body-product-detail .prices-table tr:hover {
  background-color: var(--secondairy-ultra-light-gray);
}

#body-product-detail .delete-price-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--red);
  transition: all 0.2s;
}

#body-product-detail .delete-price-button:hover {
  color: var(--dark-red);
  scale: 1.1;
}

#body-product-detail .popup-dropdown__selected {
  border: var(--secondairy-light-gray) solid 1.5px;
  border-radius: 4px;
  width: 100%;
  min-height: 30px;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
}

#body-product-detail .popup-dropdown__selected:focus {
  border: var(--primary-color-light) solid 1.5px;
  outline: none;
}

/* General expanded section styles */
#body-product-detail .has-expanded .form__section.expanded {
  display: block;
  grid-column: 1 / span 3;
  grid-row: 1 / span 2;
  z-index: 5;
  animation: fadeIn 0.3s ease;
}

/* Hide non-expanded sections when one is expanded */
#body-product-detail .has-expanded .form__section:not(.expanded) {
  display: none;
}

/* Default expanded section - content maintains original width */
#body-product-detail .form__section.expanded .prices-list,
#body-product-detail .form__section.expanded .file__uploader_section {
  margin: 0 auto;
  padding-top: 2rem;
}

/* Full width content for specific sections */
#body-product-detail .form__section.expanded.full-width-content > div,
#body-product-detail .form__section.expanded.full-width-content .input_field {
  max-width: 100%;
  width: 100%;
}

/* Adjust layout for expanded image section */
#body-product-detail .form__section.expanded #image_upload .photo-grid {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  max-width: 1200px;
  margin: 0 auto;
}
/* Remove scrolling for expanded sections */
#body-product-detail .form__section.expanded .prices-list {
  max-height: none;
  overflow: visible;
}

/* Make sure the expanded section takes full height */
#body-product-detail .form__section.expanded {
  max-height: none;
  overflow: visible;
}

@keyframes fadeIn {
  from { opacity: 0.7; }
  to { opacity: 1; }
}

.horizontal-fields {
  display: flex;
  gap: 20px;
}

.horizontal-fields .input_field {
  flex: 1;
}

/* Style for the is_active checkbox field */
.is-active-field {
  margin-bottom: 15px;
}

.is-active-field .checkbox__container {
  margin-top: 10px;
}

/* Internal SKU disabled field styling */
#products__product_internal_sku:disabled {
  background-color: #f0f0f0;
  color: #666;
  cursor: not-allowed;
  border: 1px solid #ddd;
  opacity: 0.8;
}

