.dropdown__container-dropdown.projects {
  margin-bottom: 7.5px;
}

.dropdown__container-dropdown.projects .dropdown-input--text_field {
  font-style: normal;
}

.dropdown__container-dropdown.projects .dropdown-input--text_field.placeholder {
  font-style: italic;
  color: var(--secondairy-light-medium-gray);
}

.dropdown__container-dropdown .input_field__dropdown.projects {
  position: inherit;
}

.dropdown__container-dropdown .input_field__dropdown.projects .dropdown__item {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
}

.dropdown__container-dropdown .input_field__dropdown.projects .dropdown__item--single {
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}

.dropdown__container-dropdown .input_field__dropdown.projects .dropdown__item--single {
  width: 100%;
  display: grid;
  grid-template: auto / 1fr;
}

.skeleton-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton-loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

