/* Template Modal Styles */
#unit-purchase-section .template-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  #unit-purchase-section .template-modal {
    background-color: var(--primary-black);
    border: 1px solid var(--secondairy-medium-gray);
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    max-width: 90%;
    color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  #unit-purchase-section .template-modal-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: var(--primary-black);
  }
  
  #unit-purchase-section .template-modal-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--secondairy-medium-gray);
  }
  
  #unit-purchase-section .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    padding: 4px;
  }
  
  #unit-purchase-section .close-button:hover {
    color: var(--secondairy-light-medium-gray);
  }
  
  #unit-purchase-section .template-modal-body {
    padding: 16px;
    flex: 1;
    overflow-y: auto;
  }
  
  #unit-purchase-section .form-group {
    margin-bottom: 16px;
  }
  
  #unit-purchase-section .form-group label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    color: var(--secondairy-light-medium-gray);
  }
  
  #unit-purchase-section .form-group input,
  #unit-purchase-section .form-group textarea {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid var(--secondairy-medium-gray);
    background-color: var(--secondairy-dark-gray);
    border-radius: 4px;
    font-size: 14px;
    color: var(--secondairy-light-medium-gray);
    margin-bottom: 0;
  }
  
  #unit-purchase-section .form-group input:focus,
  #unit-purchase-section .form-group textarea:focus {
    outline: none;
    border-color: var(--secondairy-light-medium-gray);
    background-color: var(--secondairy-dark-gray);
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  
  #unit-purchase-section .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  #unit-purchase-section .checkbox-group label {
    margin-bottom: 0;
    cursor: pointer;
  }
  
  #unit-purchase-section .template-modal-footer {
    padding: 12px 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    background-color: var(--primary-black);
  }
  
  #unit-purchase-section .cancel-button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    color: #333;
    font-size: 14px;
  }
  
  #unit-purchase-section .save-button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    color: white;
    font-size: 14px;
    min-width: 120px; /* Ensure consistent width during loading */
  }
  
  #unit-purchase-section .cancel-button:hover {
    background-color: var(--secondairy-light-medium-gray);
  }
  
  #unit-purchase-section .save-button:hover {
    background-color: var(--primary-color-light);
  }

  /* Disabled button styles */
  #unit-purchase-section .cancel-button:disabled,
  #unit-purchase-section .save-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  /* Save button loading state */
  .save-button-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  /* Template Name Autocomplete Dropdown */
  .template-name-container {
    position: relative;
  }
  
  .template-name-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--secondairy-dark-gray);
    border: 1px solid var(--secondairy-medium-gray);
    border-radius: 4px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .template-name-option {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    color: var(--secondairy-light-medium-gray);
    border-bottom: 1px solid var(--secondairy-medium-gray);
  }
  
  .template-name-option:last-child {
    border-bottom: none;
  }
  
  .template-name-option:hover {
    background-color: var(--primary-color-dark);
  }

  /* Confirmation Dialog */
  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
  .confirmation-dialog {
    background-color: var(--primary-black);
    border: 1px solid var(--secondairy-medium-gray);
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
    color: var(--secondairy-light-medium-gray);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .confirmation-dialog h4 {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    color: var(--secondairy-light-medium-gray);
  }
  
  .confirmation-dialog p {
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .confirmation-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }