#body-project {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  gap: 0;
  overflow-x: hidden;
}

#body-project .body__section.project {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}

#body-project .body__section.project>.icon svg {
  height: 60px !important;
  width: 100%;
  fill: var(--primary-color-light);
}

#body-project .status_bar {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 7.5px;
  height: 30px;
}

#body-project .status_bar .status {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--secondairy-light-gray);
  border: 1px solid var(--secondairy-light-medium-gray);
  color: var(--secondairy-light-medium-gray);
  padding: 5px 40px;
  border-radius: 25px;
  margin-left: -25px;
  user-select: none;
}

#body-project .status_bar .status.editable {
  cursor: pointer;
}

#body-project .status_bar .status.active.blue {
  background-color: var(--light-blue);
  border: 1px solid var(--blue);
  color: var(--blue);
}

#body-project .status_bar .status.active.red {
  background-color: var(--light-red);
  border: 1px solid var(--dark-red);
  color: var(--dark-red);
}

#body-project .status_bar .status.active.green {
  background-color: var(--light-green);
  border: 1px solid var(--dark-green);
  color: var(--dark-green);
}

#body-project .status_bar .status.active.brown {
  background-color: var(--light-brown);
  border: 1px solid var(--dark-brown);
  color: var(--dark-brown);
}

#body-project .status_bar .status.active.orange {
  background-color: var(--light-orange);
  border: 1px solid var(--dark-orange);
  color: var(--dark-orange);
}

#body-project .status_bar .status.active.gray {
  background-color: var(--secondairy-light-gray);
  border: 1px solid var(--secondairy-medium-gray);
  color: var(--secondairy-medium-gray);
}

#body-project .status_bar .status:first-child {
  margin-left: 0px;
}

#body-project .body__section.new-button {
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondairy-light-gray);
  border: var(--secondairy-light-medium-gray) solid 1px;
  color: var(--secondairy-medium-gray);
}

#body-project .unit.new-button {
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondairy-light-gray);
  border-radius: 5px;
  border: var(--secondairy-light-medium-gray) solid 1px;
  border-left-width: 1px !important;
  color: var(--secondairy-medium-gray);
}

#body-project .unit.new-button:hover {
  background-color: var(--primary-color-light);
  color: var(--primary-black);
}

#body-project .body__section.new-button:hover {
  background-color: var(--primary-color-light);
  color: var(--primary-black);
}

#project-detail-project {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#project-detail-project div>p {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary-color);
  text-align: center;
}

#project-detail-project div>p:nth-child(2) {
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondairy-light-medium-gray);
}

#body-project section {
  display: flex;
  max-width: 100%;
  gap: 15px;
  border-bottom: 10px;
}

#body-project .building>.body__section.project {
  position: relative;
  height: 15vh;
}

#body-project .building>.body__section.project.non_active > .icon, 
#body-project .building>.body__section.project.non_active > p {
  opacity: 15%;
}

#body-project .building>.body__section.project.is_sold {
  background-color: var(--ultra-light-green);
  border: solid 1px var(--light-green);
}

#body-project .building>.body__section.project.is_sold > .icon svg,
#body-project .building>.body__section.project.is_sold > .icon_is_active svg,
#body-project .building>.body__section.project.is_sold > p {
  color: var(--green);
  fill: var(--green);
}

#body-project .building>.body__section.project>.icon_is_active {
  position: absolute;
  right: 12px;
  top: 10px;
  height: 25px;
  width: 25px;
}

#body-project .building>.body__section.project>.icon_is_active svg {
  height: 100%;
  width: 100%;
  fill: var(--secondairy-light-gray);
}

#body-project .property>.body__section.project {
  position: relative;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 40px;
}

#body-project .property>.body__section.project>.icon svg {
  height: 30px !important;
}

#body-project .section__buildings {
  position: relative;
  height: calc(100% - 10vh);
}

#body-project .section__buildings .building {
  position: relative;
  height: 95%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#body-project .section__units {
  position: relative;
  height: calc(100%);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  gap: 5px;
  overflow-y: auto;
}

#body-project .section__units>.unit {
  position: relative;
  margin: 0;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 15px;
  border: var(--secondairy-light-medium-gray) solid 1px;
  border-left-width: 7px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--secondairy-medium-gray);
}

#body-project .section__units>.unit.non_active {
  opacity: 15%;
}

#body-project .section__units>.unit.is_sold {
  border: var(--light-green) solid 1px;
  border-left-width: 7px;
  color: var(--green);
}

#body-project .section__units > .unit > .sold_icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
}

#body-project .section__units > .unit > .sold_icon.is_sold svg {
  fill: var(--green);
}

#body-project .section__units > .unit > .sold_icon svg {
  height: 60%;
  fill: var(--secondairy-light-medium-gray);
}

#body-project .section__units>.unit .icon svg {
  height: 17.5px;
  width: 20px;
}

#body-project .section__units .square {
  height: 5px;
  width: 5px;
  background-color: var(--secondairy-medium-gray);
}

#body-project .section__units>div:nth-child(even) {
  background-color: var(--secondairy-light-gray);
}

#body-project section>div {
  width: 100%;
  margin: 15px 0;
}

#body-project section>div>.body__section.project {
  height: auto;
}

#body-project .project_detail_wrapper {
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}

#body-project .project_detail_wrapper .loading_spinner--circle {
  width: 100%;
  color: var(--secondairy-light-medium-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

#body-project .section__units .unit.inactive {
  color: var(--secondairy-light-medium-gray);
}

#body-project .section__units .unit.rented .unit-info-icon svg {
  fill: var(--blue);
}

#body-project .section__units .unit.sold .unit-info-icon svg {
  fill: var(--red);
}

#body-project .section__units .unit-name {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

#body-project .section__units .unit-info {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 25%;
}

#body-project .section__units .unit-info .unit-info-type {
  display: flex;
  align-items: center;
  justify-content: left;
}

#body-project .section__units .unit-info .unit-info-icon {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 25px;
  height: 25px;
  overflow: hidden;
}

#body-project .section__units .unit-info .unit-info-icon svg {
  width: 100%;
  height: 100%;
  fill: var(--secondairy-medium-gray);
  object-fit: contain;
}

#body-project .section__units .unit-info svg {
  width: 25px;
  font-size: 2.5rem;
  fill: var(--secondairy-medium-gray);
}


/* POPOUTS */


#body-project .popout_body {
  min-width: 25vw;
}

#body-project .popout_body {
  width: 75vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

#body-project .popout_body.small {
  width: 35vw;
}