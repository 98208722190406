.popup-dropdown {
    position: relative;
}

.popup-dropdown__selected {
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
    color: var(--secondairy-medium-gray);
    font-size: 0.8rem;
    font-weight: 300;
}

.popup-dropdown__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-dropdown__popup {
    background-color: var(--primary-black);
    border: 1px solid var(--secondairy-medium-gray);
    padding: 20px;
    border-radius: 5px;
    max-width: 90%;
    color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.popup-dropdown__popup .title {
    color: var(--secondairy-medium-gray);
    font-weight: 800;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 15px;
}

.popup-dropdown__options {
    max-height: 200px;
    overflow-y: auto;
    font-size: 0.8rem;
}

.popup-dropdown__search-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-dropdown__search {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    background-color: var(--secondairy-dark-gray);
    outline: none;
    border: none;
    color: var(--secondairy-light-medium-gray);
}

.popup-dropdown__clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(calc(-50% - 4px));
    background-color: transparent;
    color: var(--secondairy-medium-gray);
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.popup-dropdown__clear-button svg {
    width: 1.2rem;
    height: 1.2rem;
}

.popup-dropdown__option {
    padding: 7.5px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.popup-dropdown__option:hover {
    background-color: var(--secondairy-medium-gray);
}

.popup-dropdown__option.selected {
    background-color: var(--secondairy-dark-gray);
}

/* Scrollbar styles for WebKit browsers */
.popup-dropdown__options::-webkit-scrollbar {
    width: 8px;
}

.popup-dropdown__options::-webkit-scrollbar-track {
    background: #222;
}

.popup-dropdown__options::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 4px;
}

.popup-dropdown__options::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}

/* MOBILE */


.popup-dropdown.mobile .popup-dropdown__selected {
    background-color: var(--secondairy-light-gray-plus);
    height: 60px;
    padding: 7.5px;
    font-size: 1rem;
    color: var(--primary-black);
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
}

.popup-dropdown.mobile .popup-dropdown__popup {
    width: 90%;
    max-width: 90%;
    height: auto;
    max-height: 50%;
}

.popup-dropdown.mobile .popup-dropdown__search {
    height: 40px;
    font-size: 0.9rem;
}

.popup-dropdown.mobile .popup-dropdown__option {
    padding: 12.5px 10px;
    font-size: 0.9rem;
}

/* INPUT FIELD */

.popup-dropdown.input-field {
    width: 100%;
    padding: 5px;
    border: 1px solid var(--secondairy-light-medium-gray);
    border-radius: 4px;
    font-size: 0.8rem;
    background-color: white;
    height: 100%;
}

.popup-dropdown__icon {
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-medium-gray);
    color: var(--secondairy-medium-gray);
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0;
}
