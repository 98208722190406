.checkbox__container {
  display: flex;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
  height: 30px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.checkbox--box {
  height: 100%;
  border: var(--secondairy-light-gray) solid 1.5px;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
  margin-left: auto;
}

.checkbox--box.checked {
  background-color: var(--secondairy-light-gray);
  width: 100%;
  height: 100%;
}

.checkbox--text {
  font-size: 0.7rem;
  font-weight: 400;
  width: 50%;
}

.checkbox--box__container {
  position: relative;
  height: 100%;
}

.checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  transform-origin: center center;
  margin-left: -30%;
  margin-top: -30%;
  width: 60%;
  height: 60%;
  display: none;
}

.checkmark.checked {
  display: block;
}

.checkmark_stem {
  position: absolute;
  width: 15%;
  height: 70%;
  background-color: white;
  left: 52%;
  top: 9%;
}

.checkmark_kick {
  position: absolute;
  width: 25%;
  height: 15%;
  background-color: white;
  left: 30%;
  top: 65%;
}

/* Dark Mode */

.checkbox--dark-mode .checkbox--box {
  border-color: var(--secondairy-medium-gray);
}

.checkbox--dark-mode .checkbox--text {
  color: var(--secondairy-light-medium-gray);
}

.checkbox--dark-mode .checkmark_stem {
  background-color: var(--secondairy-light-medium-gray);
}

.checkbox--dark-mode .checkmark_kick {
  background-color: var(--secondairy-light-medium-gray);
}

.checkbox--dark-mode .checkbox--box.checked {
  background-color: var(--secondairy-dark-gray);
}














