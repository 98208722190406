.popup__container.unit-detail {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 300;
  border-radius: 0;
  backdrop-filter: blur(5px);
  padding-left: 200px;
}

.popup__container.unit-detail .input_field__label {
  font-size: 0.9rem;
}

.popup__container.unit-detail .input_field--simple .input_field__label {
  font-size: 0.8rem;
}

.popup__container.unit-detail .popup__box {
  position: relative;
  width: 95%;
  height: 80%;
  overflow: hidden;
}

.popup__container.unit-detail .popout_body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup__container.unit-detail .popout_window__header {
  position: relative;
}

.popup__container.unit-detail .popout_window__header h3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-variant: small-caps;
  color: var(--secondairy-medium-gray);
}

#popout-body-unit #unit-general-section {
  grid-template: 1fr / 1fr 1fr;
}

#popout-body-unit .popout_body__content_container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  gap: 15px;
}

#popout-body-unit .popout_body__content_container.small {
  grid-template: 1fr / 1fr;
}

#popout-body-unit .popout_body__content_container .panel {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  height: 100%;
  overflow: hidden;
}

#popout-body-unit .popout_body__content_container .form__section {
  margin: 0;
  height: 100%;

  border: none;
  border-radius: 0;
  overflow-y: scroll;

  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#popout-body-unit .popout_body__content_container .form__section .title_container {
  display: flex;
  gap: 10px;
}

.popup__container.unit-detail .popup__box h2 {
  position: relative;
  font-size: 1rem;
  color: var(--secondairy-medium-gray);
  margin: 0 10px 20px 10px;
}

.popup__container.unit-detail .popup__box .click-title {
  color: var(--secondairy-light-medium-gray);
  cursor: pointer;
}

.popup__container.unit-detail .popup__box .click-title.selected {
  color: var(--secondairy-medium-gray);
}

.popup__container.unit-detail .popup__box .form__section--content {
  overflow: scroll;
  flex-grow: 1;
}

.popup__container.unit-detail #popup-unit-notes-create,
.popup__container.unit-detail #popup-unit-notes-detail,
.popup__container.unit-detail #popup-unit-property-create {
  width: 100%;
  height: 100%;
}

/* Property Section */

#popout-body-unit #unit-property-section {
  height: 100%;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

#popout-body-unit #unit-property-section .search-bar--list {
  height: 53px;
}

#popout-body-unit #unit-property-section .properties_list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  flex-grow: 1;
  padding: 10px 0;
}

.popup__container.unit-detail .property-item {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.5fr;
  gap: 5px;
  padding: 10px;
  border-radius: 5px;
  color: var(--secondairy-medium-gray);
  font-size: 0.8rem;
  cursor: pointer;
}

.popup__container.unit-detail .property-item:nth-child(even) {
  background-color: var(--secondairy-ultra-light-gray);
}

.popup__container.unit-detail .property-item.list:nth-child(even) {
  background-color: transparent;
}

.popup__container.unit-detail .property-item.list {
  padding: 15px 10px;
  border: 1px solid var(--secondairy-light-medium-gray);
}

.popup__container.unit-detail .property-item.inactive {
  background-color: var(--secondairy-ultra-light-gray);
  color: var(--secondairy-light-medium-gray);
}

.popup__container.unit-detail .action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  min-height: 50px;
  align-items: flex-start;
}

.popup__container.unit-detail .property-details-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  min-height: 50px;
  align-items: flex-start;
}

.popup__container.unit-detail .property_details--container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  flex-grow: 1;
  overflow: scroll;
}

.popup__container.unit-detail .property_details--content {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 5px;
}

.popup__container.unit-detail .property_details--content .input_field--simple.grid-2 {
  grid-column: span 2;
  grid-template: 1fr / 0.25fr 1fr;
}

.popup__container.unit-detail .property_details--content .input_field__input_box--text-area {
  min-height: auto;
}

.popup__container.unit-detail .transactions_list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.popup__container.unit-detail .transaction-item {
  display: grid;
  grid-template: 1fr / 60px 1fr 1fr;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 5px;
  height: 50px;
  color: var(--secondairy-medium-gray);
  font-size: 0.8rem;
  border: 1px solid var(--secondairy-light-medium-gray);
  cursor: pointer;
}

.popup__container.unit-detail .current-owners-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--secondairy-light-medium-gray);
  grid-column: span 2;
}

.popup__container.unit-detail .current-owners-section h3 {
  margin-bottom: 12px;
  font-size: 0.9rem;
  color: var(--secondairy-medium-gray);
}

.popup__container.unit-detail .owners-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.popup__container.unit-detail .owner-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.popup__container.unit-detail .owner-item:hover {
  background-color: #eee;
}

.popup__container.unit-detail .owner-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.popup__container.unit-detail .owner-name {
  font-weight: 500;
}

.popup__container.unit-detail .owner-type {
  font-size: 0.85rem;
  color: #666;
}

.popup__container.unit-detail .owner-percentage {
  font-weight: 500;
  color: #444;
}

.popup__container.unit-detail .fraction-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.popup__container.unit-detail .fraction-inputs input {
  width: calc(50% - 12px);
}

.popup__container.unit-detail .fraction-divider {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--secondairy-medium-gray);
}

.co-ownership-fraction .fraction-inputs {
  grid-column: 2;
}
/* Rental Section */

#popout-body-unit #unit-rental-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

/* Loading Container */
#popout-body-unit #unit-purchase-section .loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: var(--secondairy-light-medium-gray);
}

#popout-body-unit #unit-purchase-section .loading-container p {
  margin-top: 16px;
  font-size: 14px;
}

/* Price Section */

#unit-price-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

#unit-price-section .price-section {
  background: white;
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 5px;
  padding: 15px;
}

#unit-price-section .price-section h3 {
  color: var(--secondairy-medium-gray);
  font-size: 0.9rem;
  margin-bottom: 15px;
  padding-bottom: 8px;
}

#unit-price-section .price-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

#unit-price-section .payment-stages {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 500px;
  gap: 5px;
}

#unit-price-section .stage-row {
  display: grid;
  grid-template-columns: 60px 1fr 100px 40px;
  gap: 10px;
  align-items: center;
}

#unit-price-section .add-stage-btn {
  margin-top: 10px;
  padding: 8px;
  background-color: var(--secondairy-ultra-light-gray);
  border: 1px solid var(--secondairy-light-medium-gray);
  border-radius: 4px;
  color: var(--secondairy-medium-gray);
  cursor: pointer;
  width: fit-content;
}

#unit-price-section .delete-stage-btn {
  background-color: var(--ultra-light-red);
  border: 1px solid var(--red);
  border-radius: 4px;
  color: var(--red);
  cursor: pointer;
  width: fit-content;
  height: 100%;
  width: 33px;
}

#unit-price-section .price-grid.horizontal {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#unit-price-section .price-grid.horizontal .price-inputs {
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
}

#unit-price-section .input-with-euro {
  position: relative;
  display: flex;
  align-items: center;
}

#unit-price-section .euro-sign {
  position: absolute;
  left: 8px;
  color: var(--secondairy-medium-gray);
}

#unit-price-section .input-with-euro input {
  padding-left: 25px;
}

#unit-price-section .input_field--combo-grid {
  flex: 1;
  min-width: 0;
}

#unit-price-section .total-row {
  padding-top: 10px;
  border-top: 1px solid var(--secondairy-light-medium-gray);
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  color: var(--secondairy-medium-gray);
}

