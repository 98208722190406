.add_company_to_contact__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}

.add_company_to_contact__container .input_field__input_box--text {
  margin-top: 10px;
  height: 30px;
  width: 50%;
}

.add_company_to_contact__container .delete_box {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  margin-top: 10px;
  height: 30px;
  color: white;
  background-color: var(--red);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 0.75;
  cursor: pointer;
  width: 0;
  padding: 0;
  pointer-events: none;
  overflow: hidden;
  transition: all 0.3s ease;
}

.add_company_to_contact__container:hover .delete_box {
  width: 60px;
  padding: 5px;
  pointer-events: auto;
}

