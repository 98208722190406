#body-company-detail {
    grid-template: 1.6fr 1fr / 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "details details details details details details"
      "tags tags notes notes contacts contacts"
    ;
  }
  
  #body-company-detail .input_field {
    margin-top: 10px;
  }
  
  #body-company-detail h2 {
    cursor: pointer;
  }
  
  #company-details {
    width: 100%;
    display: grid;
    grid-template: auto / repeat(3, 1fr);
    gap: 10px;
    grid-area: details;
  }
  
  #company-notes {
    grid-area: notes;
  }
  
  #company-tags {
    grid-area: tags;
  }
  
  #company-companies {
    grid-area: companies;
  }
  
  #company-contacts {
    grid-area: contacts;
  }
  
  .full-screen {
    grid-area: 1 / 1 / -1 / -1 !important;
    z-index: 10;
  }
  
  .note__container--popup {
    display: grid;
    grid-template: auto / 1fr;
    gap: 0px;
    margin-bottom: 10px;
    padding: 7.5px;
    align-items: center;
    cursor: pointer;
  }
  
  #body-company-detail .note__container--popup p {
    color: var(--secondairy-medium-gray);
  }
  
  .note__container--popup .note_icon--popup {
    display: flex;
    align-items: center;
    padding: 0;
  }
  
  .note__container--popup .note_icon--popup img {
    height: 30px;
  }
  
  .note__container--popup .date_note--popup {
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    font-size: 0.7rem;
    gap: 10px;
  }
  
  .note__container--popup .note_text--popup {
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1rem;
  }