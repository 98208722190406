.popup__overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
}

.popup__overlay.active {
    position: fixed;
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
}

.popup__container {
    position: absolute;
    z-index: 101;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
    background-color: white;
    padding: 15px;

    opacity: 0;
    pointer-events: none;

    transition: all 200ms ease-in-out;
}

.popup__container.active {
    pointer-events: all;
    opacity: 1; 
}

.popup__container .popout_window__header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 50px;
}

.popup__container .popout_window__header .primary-button-header {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.popup__container .popout_window__header--title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--primary-color);
    width: 100%;
}

.popup__container .menu__titles {
    margin: 15px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.popup__container .menu__titles h3 {
    padding: 7.5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--secondairy-light-medium-gray);
    border-bottom: var(--secondairy-light-medium-gray) 2px solid;
    cursor: pointer;
    font-weight: 500;
    font-variant: small-caps;

    transition: all 300ms ease-in-out;
}

.popup__container .menu__titles h3:hover {
    color: var(--primary-color-light);
    border-bottom: var(--primary-color-light) 2px solid;
}

.popup__container .menu__titles h3.selected {
    color: var(--primary-color-light);
    border-bottom: var(--primary-color-light) 2px solid;
}

.popup__box {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: white;
    padding: 35px;
    cursor: auto;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    box-shadow: var(--border-shadow-float);
    border: 1px solid var(--secondairy-light-medium-gray);
}

.popup__box .close_button {
    position: absolute;
    right: -2px;
    top: -2px;
    cursor: pointer;
    /* background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-gray); */
    border-radius: 20px;
    padding: 7.5px 15px;
    font-size: 0.8rem;
    color: var(--secondairy-medium-gray);
}

.popup__box .close_button:hover {
    color: var(--primary-black);
    background-color: var(--secondairy-light-gray);
}

.popup__container.overlay #popup__close-icon {
    position: absolute;
    color: var(--primary-black);
    font-size: 0.7rem;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-gray);
    border-radius: 20px;
    width: 25px;
    height: 18px;
    padding: 3px;
    z-index: 150;
}

.form__section_title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primary-green);
    margin-bottom: 40px;
}

.popup__container .popup_window__subtitle {
    font-weight: 700;
    color: var(--primary-black);
    font-variant: all-small-caps;
    margin: 10px 0;
    font-size: 1rem;
}

.popup__container .form__section {
    padding: 20px;
}



