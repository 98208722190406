.popout__container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 105;
    width: calc(100%);
    right: 0;
    height: calc(100% + 10vh + 20px);
    top: -12.5vh;
    background-color: transparent;
    backdrop-filter: blur(10px);
    padding: 15px;
    padding-top: calc(10vh + 15px);

    opacity: 0;
    pointer-events: none;

    transition: all 200ms ease-in-out;
}

.popout__container.active {
    pointer-events: all;
    opacity: 1; 
}

.popout__container .popup__options-button {
    position: relative;
    left: 0;
    top: 0;
}

.popout__box {
    position: relative;
    max-height: 90%;
    border: var(--secondairy-light-medium-gray) solid 1px;
    box-shadow: var(--border-shadow-min);
    border-radius: 10px;
    overflow-y: scroll;  
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    cursor: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.popout__container #popup__close-icon {
    position: absolute;
    color: var(--primary-black);
    font-size: 0.7rem;
    right: 8px;
    top: 8px;
    cursor: pointer;
    background-color: var(--secondairy-ultra-light-gray);
    border: 1px solid var(--secondairy-light-gray);
    border-radius: 20px;
    width: 25px;
    height: 18px;
    padding: 3px;
}

.popout__container .form__section_title {
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--secondairy-medium-gray);
    margin: 17.5px 0 10px 0;
    text-transform: uppercase;
}

.popout__container h3 {
    font-weight: 700;
    color: var(--primary-black);
    font-variant: all-small-caps;
    margin: 20px 0 10px 0;
    font-size: 1rem;
    width: 100%;
    text-align: center;
}

.popout__container .form__section {
    padding: 0;
    margin-top: 20px;
    padding-bottom: 40px;
    border: none;
    border-radius: 0;
    overflow: scroll;
}

.popout__container .form__section .input_field  {
    margin-top: 20px;
}

.popout__container .form__section .input_field .input_field__input_box--text {
    height: 40px;
}

.popout__container .form__section .MuiInputBase-root {
    height: 40px;
    width: 50%;
}

