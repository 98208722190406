#body-projects {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#body-projects .table__row--field.tags {
    display: flex;
    gap: 5px;
}

#body-projects .table__row--field.tags .tag {
    border: 1px solid var(--secondairy-medium-gray);
    padding: 4px 10px;
    border-radius: 10px;
    font-size: 0.75rem;
}

#body-projects .table__header-4,
#body-projects .table__row-4
{
    grid-template: 35px / 80px 200px 200px 220px 1fr;
    cursor: pointer;
}

#new-project-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    height: 40%;
}

#all-projects-table {
    height: 60%;
}

#all-projects-table .table__row--field {
    overflow-x: auto;
}