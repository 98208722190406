.popup__container.project-detail {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 300;
    border-radius: 0;
    backdrop-filter: blur(5px);
    padding-left: 200px;
}

.popup__container.project-detail .input_field__label {
    font-size: 0.9rem;
}

.popup__container.project-detail .popup__box {
    position: relative;
    width: 95%;
    height: 80%;
    border: 1px solid var(--secondairy-light-medium-gray);
    overflow: hidden;
}

.popup__container.project-detail .popup__box #popup__close-icon {
    z-index: 101;
}

.popup__container.project-detail .popup__box .popout_body {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 100;
    flex-grow: 1;
}

.popup__container.project-detail .popup__box .popout_body .more--btn {
    margin: 0;
    margin-bottom: 20px;
}

.popup__container.project-detail .popup__box .details_container {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    column-gap: 20px;
    overflow: hidden;
    flex-grow: 1;
}

.popup__container.project-detail .popup__box .details_container.content {
    display: flex;
    column-gap: 20px;
    overflow: scroll;
    height: 100%;
}

.popup__container.project-detail .popup__box .body__section {
    position: relative;
    border: none;
    box-shadow: none;
    height: 100%;
    margin: 0;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.popup__container.project-detail .popup__box .body__section h2 {
    position: absolute;
    top: 0;
    font-size: 1rem;
    text-align: center;
}

.popup__container.project-detail .popup__box .body__section h3 {
    color: var(--secondairy-medium-gray);
    font-variant: small-caps;
    font-size: 1rem;
    margin: 15px 0;
}

.popup__container.project-detail .popup__box #project-detail-notes {
    grid-row: span 2;
}

.popup__container.project-detail .popup__box #project-detail-notes #popup-project-notes-create,
.popup__container.project-detail .popup__box #project-detail-notes #popup-project-notes-detail {
    width: 100%;
    height: 100%;
}

.popup__container.project-detail .popup__box .body__section .form__section {
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.popup__container.project-detail .popup__box .body__section .form__section--content {
    overflow: scroll;
    flex-grow: 1;
    margin: 30px 15px;
}

.popup__container.project-detail #project-detail-content .sliders {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 20%;
}

.popup__container.project-detail #project-detail-content .content_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.popup__container.project-detail #project-detail-content .content_container .description_container {
    width: 100%;
    display: flex;
    gap: 15px;
}

.popup__container.project-detail #project-detail-content .details_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.popup__container.project-detail #project-detail-content .details_container .details_project {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.popup__container.project-detail #project-detail-content .details_container .details_project h4 {
    padding: 7.5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0,85rem;
    color: var(--secondairy-medium-gray);
    font-weight: 500;
}

.popup__container.project-detail #project-detail-content .details_container .program {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr) 50px;
    gap: 15px;
}

.popup__container.project-detail #project-detail-content .details_container .program p {
    display: flex;
    align-items: center;
    justify-content: left;
}

.popup__container.project-detail #project-detail-content .details_container .program .delete_icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.5rem;
    color: var(--red);
    cursor: pointer;
    margin-bottom: 12.5px;
}