.dropdown__container.filter {
  position: relative;
  align-self: flex-end;
  width: 25%;
}

.dropdown__container.filter .input_field__input_box--text--filter {
  cursor: pointer;
  height: 40px;
  box-shadow: var(--border-shadow-min);
  background-color: white;
  border: none;
  height: 30px;
  margin-top: 0;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  resize: vertical;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 7.5px;
  padding-left: 7.5px;
  z-index: 11;
}

.input_field__input_box--text--filter p {
  font-weight: 300;
  font-style: italic;
}

.input_field__input_box--text--filter svg {
  margin-left: auto;
  height: 120%;
  color: var(--secondairy-medium-gray);
}

.dropdown__container.filter .search-bar {
  width: 100%;
  font-size: 0.65rem;
  padding: 8px 10px;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 0;
}

.dropdown__container.filter .input_field__dropdown {
  top: 27.5px;
  padding-top: 7.5px;
  z-index: 10;
}

.dropdown-input--text_field {
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray)
}

.search-bar svg {
  margin-right: 5px;

}

.search-bar input {
  border: none;
  border-radius: 7.5px;
  width: 100%;
  height: 100%;
  padding: 7.5px;
  font-size: 0.7rem;
  color: var(--secondairy-medium-gray);
  font-weight: 300;
  font-style: italic;
}

.search-bar input::placeholder {
  font-style: italic;
  color: var(--secondairy-medium-gray);
}

.search-bar input:focus {
  outline: none;
}

