#body-contact-detail {
  grid-template: 1.6fr 1fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "details details details details details details"
    "tags tags notes notes companies companies"
  ;
}

#body-contact-detail .input_field {
  margin-top: 10px;
}

#body-contact-detail h2 {
  cursor: pointer;
}

#contact-details {
  width: 100%;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  gap: 10px;
  grid-area: details;
}

#contact-notes {
  grid-area: notes;
}

#contact-tags {
  grid-area: tags;
}

#contact-companies {
  grid-area: companies;
}

.full-screen {
  grid-area: 1 / 1 / -1 / -1 !important;
  z-index: 10;
}

.note__container--popup {
  display: grid;
  grid-template: auto / 1fr;
  margin-bottom: 10px;
  padding: 7.5px;
  align-items: center;
  cursor: pointer;
}

#body-contact-detail .note__container--popup p {
  color: var(--secondairy-medium-gray);
}

.note__container--popup .note_icon--popup {
  display: flex;
  align-items: center;
  padding: 0;
}

.note__container--popup .note_icon--popup img {
  height: 30px;
}

.note__container--popup .date_note--popup {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
}

.note__container--popup .note_text--popup {
  font-size: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1rem;
}