#body-products {
    display: flex;
    flex-direction: column;
}

#body-products .flex {
    gap: 5px;
}

#new-product-form {
    width: 100%;
    height: 65%;
    display: grid;
    grid-template: auto / repeat(3, 1fr);
    gap: 10px;
}

#new-product-form .input_field textarea {
    min-height: 90px;
}

#all-products-table {
    width: 100%;
    height: 35%;
}

#body-products .table__header-5 {
    grid-template: auto / 50px 0.8fr 0.8fr 1fr 0.8fr 1.5fr;
}

#body-products .table__row-5 {
    grid-template: minmax(35px, auto) / 50px 0.8fr 0.8fr 1fr 0.8fr 1.5fr;
    cursor: pointer;
    height: auto;
}

#body-products .table__row-5 img {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

#body-products .table__header--field {
    cursor: pointer;
}

#body-products .file__uploader_section {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#body-products .file__uploader_section h1 {
    text-align: left;
}

#body-products .labels-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: fit-content;
}

#body-products .label-item {
    margin: 0 5px 5px 0;
    background-color: var(--secondairy-ultra-light-gray);
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    white-space: nowrap;
}

#body-products .sku-label {
    background-color: var(--ultra-light-blue);
    color: var(--dark-blue);
}

#body-products .inactive-label {
    background-color: var(--light-red);
    color: var(--red);
    margin-left: 5px;
}

#body-products .table__row--field {
    height: auto;
    display: flex;
    align-items: center;
    min-height: 35px;
}

/* Hide Inactive filter styles */
.hide-inactive-filter {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100%;
    border-radius: 4px;
    background-color: white;
    box-shadow: var(--border-shadow-min);
    color: var(--secondairy-medium-gray);
    border-radius: 7.5px;
    height: 30px;
}

.hide-inactive-filter .slider {
    margin: 0;
}

/* Style for the is_active checkbox field */
.is-active-field {
  margin-bottom: 15px;
}

.is-active-field .checkbox__container {
  margin-top: 10px;
}

.form-info-note {
  margin-top: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  border-left: 3px solid #6c757d;
}

.form-info-note p {
  color: #6c757d;
  margin: 0;
  font-size: 0.9rem;
}