
.body__signup_page {
  min-height: 100vh;
  background: var(--primary-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.body__signup_page a {
  position: relative;
  margin: 40px 0;
}

.body__signup_page img {
  position: relative;
  width: 42.5vh;
}